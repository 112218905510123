import React, { useCallback, useEffect, useState } from 'react';
import './App.css';
import moment from 'moment';
import logoHangseng from './resources/images/logo_hangseng.png';
import hangHender from './resources/images/hang-header.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { services } from './services/services';
import { PeriodType } from './mytype/period';
import { utils } from './utils/utils';

const hangsengMorningAnnounceDate = moment().format('YYYY-MM-DD 10:35:00'); // 10.25 close
const hangsengEveningAnnounceDate = moment().format('YYYY-MM-DD 15:25:00'); // 15.15 close

const App = () => {

  const [time, setTime] = useState(moment().format("DD-MM-YYYY HH:mm"));
  const [hangsengMorningStat, setHangsengMorningStat] = useState<PeriodType[]>([]);
  const [hangsengEveningStat, setHangsengEveningStat] = useState<PeriodType[]>([]);
  const [hangsengMorningCurrent, setHangsengMorningCurrent] = useState<PeriodType | null>(null);
  const [hangsengEveningCurrent, setHangsengEveningCurrent] = useState<PeriodType | null>(null);

  const [hsIndexPositiveOrNegative, setHsIndexPositiveOrNegative] = useState("+");
  const hsIndexStart = 35000.00;
  const [hsIndex, setHsIndex] = useState(35000.00);
  const [hsIndexChange, setHsIndexChange] = useState(0.00);
  const [hsIndexPercent, setHsIndexPercent] = useState(0.00);

  const [hscePositiveOrNegative, setHscePositiveOrNegative] = useState("+");
  const hsceStart = 15000.00;
  const [hsce, setHsce] = useState(15000.00);
  const [hsceChange, setHsceChange] = useState(0.00);
  const [hscePercent, setHscePercent] = useState(0.00);

  const [hsTechPositiveOrNegative, setHsTechPositiveOrNegative] = useState("+");
  const hsTechStart = 15000.00;
  const [hsTech, setHsTech] = useState(15000.00);
  const [hsTechChange, setHsTechChange] = useState(0.00);
  const [hsTechPercent, setHsTechPercent] = useState(0.00);

  const [hsComIndexPositiveOrNegative, setHsComIndexPositiveOrNegative] = useState("+");
  const hsComIndexStart = 7000.00;
  const [hsComIndex, setHsComIndex] = useState(7000.00);
  const [hsComIndexChange, setHsComIndexChange] = useState(0.00);
  const [hsComIndexPercent, setHsComIndexPercent] = useState(0.00);

  const getHangsengMorningStatData = useCallback(async () => {
    const response = await services.getStatistics(14);
    setHangsengMorningStat(response.data);
  }, []);

  const getHangsengEveningStatData = useCallback(async () => {
    const response = await services.getStatistics(15);
    setHangsengEveningStat(response.data);
  }, []);

  useEffect(() => {

    getHangsengMorningStatData();
    getHangsengEveningStatData();

    hangsengMorningData();
    hangsengEveningData();

    // every 5 min
    triggerData();
    const interval = setInterval(() => {
      triggerData();
    }, 5 * (60 * 1000));

    return () => clearInterval(interval);
  }, [getHangsengMorningStatData, getHangsengEveningStatData]);

  const hangsengMorningData = async () => {
    // console.log("Morning Data");

    if (moment().toDate().getTime() < moment(hangsengMorningAnnounceDate).toDate().getTime()) {
      // show null
      return setHangsengMorningCurrent(null);
    }

    if (moment().toDate().getTime() >= moment(hangsengMorningAnnounceDate).toDate().getTime()) {
      // show release
      if (!hangsengMorningCurrent) {
        const current = await services.getCurrentPeriods(14);
        return setHangsengMorningCurrent(current.data);
      }
    }
  }

  const hangsengEveningData = async () => {
    // console.log("Evening Data");

    if (moment().toDate().getTime() < moment(hangsengEveningAnnounceDate).toDate().getTime()) {
      // show null
      return setHangsengEveningCurrent(null);
    }

    if (moment().toDate().getTime() >= moment(hangsengEveningAnnounceDate).toDate().getTime()) {
      // show release
      if (!hangsengEveningCurrent) {
        const current = await services.getCurrentPeriods(15);
        return setHangsengEveningCurrent(current.data);
      }
    }
  }

  const randomNumber = () => {
    let hsIndexRandUpDown = Math.round(Math.random());
    if (hsIndexRandUpDown) hsIndexRandUpDown = 1.00;
    else hsIndexRandUpDown = -1.00;

    let hsceRandUpDown = Math.round(Math.random());
    if (hsceRandUpDown) hsceRandUpDown = 1.00;
    else hsceRandUpDown = -1.00;

    let hsTechRandUpDown = Math.round(Math.random());
    if (hsTechRandUpDown) hsTechRandUpDown = 1.00;
    else hsTechRandUpDown = -1.00;

    let hsComIndexRandUpDown = Math.round(Math.random());
    if (hsComIndexRandUpDown) hsComIndexRandUpDown = 1.00;
    else hsComIndexRandUpDown = -1.00;

    // HS INDEX
    const hsIndexPrecision = 100; // 2 decimals
    const hsIndexPrecisionNum = Math.floor(Math.random() * (1000 * hsIndexPrecision - 1 * hsIndexPrecision) + 1 * hsIndexPrecision) / (1 * hsIndexPrecision);

    const hsIndexChangePrecision = 100; // 2 decimals
    const hsIndexChangePrecisionNum = Math.floor(Math.random() * (1000 * hsIndexChangePrecision - 1 * hsIndexChangePrecision) + 1 * hsIndexChangePrecision) / (1 * hsIndexChangePrecision);

    const hsIndexPercentPrecision = 100; // 2 decimals
    const hsIndexPercentPrecisionNum = Math.floor(Math.random() * (10 * hsIndexPercentPrecision - 1 * hsIndexPercentPrecision) + 1 * hsIndexPercentPrecision) / (1 * hsIndexPercentPrecision);

    // HS China Enterprise
    const hscePrecision = 100; // 2 decimals
    const hscePrecisionNum = Math.floor(Math.random() * (1000 * hscePrecision - 1 * hscePrecision) + 1 * hscePrecision) / (1 * hscePrecision);

    const hsceChangePrecision = 100; // 2 decimals
    const hsceChangePrecisionNum = Math.floor(Math.random() * (1000 * hsceChangePrecision - 1 * hsceChangePrecision) + 1 * hsceChangePrecision) / (1 * hsceChangePrecision);

    const hscePercentPrecision = 100; // 2 decimals
    const hscePercentPrecisionNum = Math.floor(Math.random() * (10 * hscePercentPrecision - 1 * hscePercentPrecision) + 1 * hscePercentPrecision) / (1 * hscePercentPrecision);

    // HS Tech
    const hsTechPrecision = 100; // 2 decimals
    const hsTechPrecisionNum = Math.floor(Math.random() * (1000 * hsTechPrecision - 1 * hsTechPrecision) + 1 * hsTechPrecision) / (1 * hsTechPrecision);

    const hsTechChangePrecision = 100; // 2 decimals
    const hsTechChangePrecisionNum = Math.floor(Math.random() * (1000 * hsTechChangePrecision - 1 * hsTechChangePrecision) + 1 * hsTechChangePrecision) / (1 * hsTechChangePrecision);

    const hsTechPercentPrecision = 100; // 2 decimals
    const hsTechPercentPrecisionNum = Math.floor(Math.random() * (10 * hsTechPercentPrecision - 1 * hsTechPercentPrecision) + 1 * hsTechPercentPrecision) / (1 * hsTechPercentPrecision);

    // HS Composite Index
    const hsComIndexPrecision = 100; // 2 decimals
    const hsComIndexPrecisionNum = Math.floor(Math.random() * (1000 * hsComIndexPrecision - 1 * hsComIndexPrecision) + 1 * hsComIndexPrecision) / (1 * hsComIndexPrecision);

    const hsComIndexChangePrecision = 100; // 2 decimals
    const hsComIndexChangePrecisionNum = Math.floor(Math.random() * (1000 * hsComIndexChangePrecision - 1 * hsComIndexChangePrecision) + 1 * hsComIndexChangePrecision) / (1 * hsComIndexChangePrecision);

    const hsComIndexPercentPrecision = 100; // 2 decimals
    const hsComIndexPercentPrecisionNum = Math.floor(Math.random() * (10 * hsComIndexPercentPrecision - 1 * hsComIndexPercentPrecision) + 1 * hsComIndexPercentPrecision) / (1 * hsComIndexPercentPrecision);

    setHsIndex(hsIndexStart + (hsIndexPrecisionNum * hsIndexRandUpDown));
    setHsIndexChange(hsIndexChangePrecisionNum * hsIndexRandUpDown);
    setHsIndexPercent(hsIndexPercentPrecisionNum * hsIndexRandUpDown);
    setHsIndexPositiveOrNegative(hsIndexRandUpDown === 1.00 ? "+" : "-");

    setHsce(hsceStart + (hscePrecisionNum * hsceRandUpDown));
    setHsceChange(hsceChangePrecisionNum * hsceRandUpDown);
    setHscePercent(hscePercentPrecisionNum * hsceRandUpDown);
    setHscePositiveOrNegative(hsceRandUpDown === 1.00 ? "+" : "-");

    setHsTech(hsTechStart + (hsTechPrecisionNum * hsTechRandUpDown));
    setHsTechChange(hsTechChangePrecisionNum * hsTechRandUpDown);
    setHsTechPercent(hsTechPercentPrecisionNum * hsTechRandUpDown);
    setHsTechPositiveOrNegative(hsTechRandUpDown === 1.00 ? "+" : "-");

    setHsComIndex(hsComIndexStart + (hsTechPrecisionNum * hsComIndexRandUpDown));
    setHsComIndexChange(hsComIndexChangePrecisionNum * hsComIndexRandUpDown);
    setHsComIndexPercent(hsComIndexPercentPrecisionNum * hsComIndexRandUpDown);
    setHsComIndexPositiveOrNegative(hsComIndexRandUpDown === 1.00 ? "+" : "-");
  }

  const triggerData = async () => {
    // randomNumber();
    setTime(moment().format("DD-MM-YYYY HH:mm"));

    if (
      moment().toDate().getTime() >= moment(hangsengMorningAnnounceDate).toDate().getTime() &&
      moment().toDate().getTime() < moment(moment().format("YYYY-MM-DD 13:00:00")).toDate().getTime()
    ) {
      // show release
      if (!hangsengMorningCurrent) {
        const currentMorning = await services.getCurrentPeriods(14);
        const data: PeriodType = currentMorning.data;
        const num = parseInt(hsIndex.toString().substring(0, 4) + data.up3) * 0.01;

        const precisionDown = 100; // 2 decimals
        const numDown = Math.floor(Math.random() * (1000 * precisionDown - 1 * precisionDown) + 1 * precisionDown) / (1 * precisionDown);

        const hsIndexPercentPrecision = 100; // 2 decimals
        const hsIndexPercentPrecisionNum = Math.floor(Math.random() * (10 * hsIndexPercentPrecision - 1 * hsIndexPercentPrecision) + 1 * hsIndexPercentPrecision) / (1 * hsIndexPercentPrecision);

        let hsIndexRandUpDown = Math.round(Math.random());
        if (hsIndexRandUpDown) hsIndexRandUpDown = 1.00;
        else hsIndexRandUpDown = -1.00;

        setHsIndexChange(parseFloat(numDown.toString().split(".")[0] + "." + (data.down2)?.toString()));
        setHsIndex(num);
        setHsIndexPercent(hsIndexPercentPrecisionNum * hsIndexRandUpDown);
        return setHangsengMorningCurrent(currentMorning.data);
      }

      const num = parseInt(hsIndex.toString().substring(0, 4) + hangsengMorningCurrent.up3) * 0.01;

      const precisionDown = 100; // 2 decimals
      const numDown = Math.floor(Math.random() * (1000 * precisionDown - 1 * precisionDown) + 1 * precisionDown) / (1 * precisionDown);

      const hsIndexPercentPrecision = 100; // 2 decimals
      const hsIndexPercentPrecisionNum = Math.floor(Math.random() * (10 * hsIndexPercentPrecision - 1 * hsIndexPercentPrecision) + 1 * hsIndexPercentPrecision) / (1 * hsIndexPercentPrecision);

      let hsIndexRandUpDown = Math.round(Math.random());
      if (hsIndexRandUpDown) hsIndexRandUpDown = 1.00;
      else hsIndexRandUpDown = -1.00;

      setHsIndexChange(parseFloat(numDown.toString().split(".")[0] + "." + (hangsengMorningCurrent.down2)?.toString()));
      setHsIndexPercent(hsIndexPercentPrecisionNum * hsIndexRandUpDown);
      return setHsIndex(num);
    }

    if (
      moment().toDate().getTime() >= moment(hangsengEveningAnnounceDate).toDate().getTime() &&
      moment().toDate().getTime() <= moment(moment().format("YYYY-MM-DD 23:59:59")).toDate().getTime()
    ) {
      // show release
      if (!hangsengEveningCurrent) {
        const currentEvening = await services.getCurrentPeriods(15);
        const data: PeriodType = currentEvening.data;
        const num = parseInt(hsIndex.toString().substring(0, 4) + data.up3) * 0.01;

        const precisionDown = 100; // 2 decimals
        const numDown = Math.floor(Math.random() * (1000 * precisionDown - 1 * precisionDown) + 1 * precisionDown) / (1 * precisionDown);

        const hsIndexPercentPrecision = 100; // 2 decimals
        const hsIndexPercentPrecisionNum = Math.floor(Math.random() * (10 * hsIndexPercentPrecision - 1 * hsIndexPercentPrecision) + 1 * hsIndexPercentPrecision) / (1 * hsIndexPercentPrecision);

        let hsIndexRandUpDown = Math.round(Math.random());
        if (hsIndexRandUpDown) hsIndexRandUpDown = 1.00;
        else hsIndexRandUpDown = -1.00;

        setHsIndexChange(parseFloat(numDown.toString().split(".")[0] + "." + (data.down2)?.toString()));
        setHsIndexPercent(hsIndexPercentPrecisionNum * hsIndexRandUpDown);
        setHsIndex(num);
        return setHangsengEveningCurrent(currentEvening.data);
      }

      const num = parseInt(hsIndex.toString().substring(0, 4) + hangsengEveningCurrent.up3) * 0.01;

      const precisionDown = 100; // 2 decimals
      const numDown = Math.floor(Math.random() * (1000 * precisionDown - 1 * precisionDown) + 1 * precisionDown) / (1 * precisionDown);

      const hsIndexPercentPrecision = 100; // 2 decimals
      const hsIndexPercentPrecisionNum = Math.floor(Math.random() * (10 * hsIndexPercentPrecision - 1 * hsIndexPercentPrecision) + 1 * hsIndexPercentPrecision) / (1 * hsIndexPercentPrecision);

      let hsIndexRandUpDown = Math.round(Math.random());
      if (hsIndexRandUpDown) hsIndexRandUpDown = 1.00;
      else hsIndexRandUpDown = -1.00;

      setHsIndexChange(parseFloat(numDown.toString().split(".")[0] + "." + (hangsengEveningCurrent.down2)?.toString()));
      setHsIndexPercent(hsIndexPercentPrecisionNum * hsIndexRandUpDown);
      return setHsIndex(num);
    }

    if (moment().toDate().getTime() < moment(hangsengMorningAnnounceDate).toDate().getTime()) {
      return randomNumber();
    }

    if (
      moment().toDate().getTime() >= moment(moment().format("YYYY-MM-DD 13:00:00")).toDate().getTime()
      && moment().toDate().getTime() < moment(hangsengEveningAnnounceDate).toDate().getTime()
    ) {
      return randomNumber();
    }
  }

  const morningClose = () => {
    // if (!nikkeiMorningCurrent) {
    //   return (
    //     <>
    //       <td>Monring Closed</td>
    //       <td>Up</td>
    //       <td></td>
    //       <td>Down</td>
    //       <td></td>
    //     </>
    //   )
    // }
    return (
      <>
        <td style={{ width: '30%' }}>Morning Closed</td>
        <td style={{ width: '10%' }}>Up</td>
        <td style={{ width: '20%' }}>{hangsengMorningCurrent?.up3}</td>
        <td style={{ width: '10%' }}>Down</td>
        <td style={{ width: '20%' }}>{hangsengMorningCurrent?.down2}</td>
      </>
    )
  }

  const eveningClose = () => {
    // if (!nikkeiMorningCurrent) {
    //   return (
    //     <>
    //       <td>Monring Closed</td>
    //       <td>Up</td>
    //       <td></td>
    //       <td>Down</td>
    //       <td></td>
    //     </>
    //   )
    // }
    return (
      <>
        <td style={{ width: '30%' }}>Evening Closed</td>
        <td style={{ width: '10%' }}>Up</td>
        <td style={{ width: '20%' }}>{hangsengEveningCurrent?.up3}</td>
        <td style={{ width: '10%' }}>Down</td>
        <td style={{ width: '20%' }}>{hangsengEveningCurrent?.down2}</td>
      </>
    )
  }

  const renderStat = () => {
    if (hangsengMorningStat.length === 0 || hangsengEveningStat.length === 0) {
      return (
        <tr className="text-center">
          <td colSpan={7}>No Statistics</td>
        </tr>
      )
    }

    const stat = [];

    for (let i = 0; i < hangsengMorningStat.length; i++) {
      const element = (
        <tr>
          <td>{moment(hangsengMorningStat[i].dateAnnounce).format("DD/MM/YYYY")}</td>
          <td>{hangsengMorningStat[i].up3}</td>
          <td>{hangsengMorningStat[i].down2}</td>
          <td>{hangsengEveningStat[i].up3}</td>
          <td>{hangsengEveningStat[i].down2}</td>
        </tr>
      );

      stat.push(element);
    }

    return stat;
  }

  return <div className="layout">
    <div className="container p-0">
      <div id="hkindex">
        <div className="header">
          <img src={hangHender} style={{ width: '100%' }} />
        </div>
        <div className="top-nav">
          <span>
            <a href="#">About Us</a>
          </span>
          <span>
            <a href="#">Contact Us</a>
          </span>
          <span>
            <a href="#">繁</a>
          </span>
          <span style={{ borderRight: 0 }}>
            <a href="#">简</a>
          </span>
        </div>
        <div className="nav">
          <img src={logoHangseng} />
          <div className="menu">
            <ul>
              <li><a href="#">Indexes</a></li>
              <li><a href="#">Our Services</a></li>
              <li><a href="#">ESG Service</a></li>
              <li><a href="#">Resources</a></li>
              <li><a href="#">Newsroom</a></li>
            </ul>
          </div>
        </div>
        <div className="content">
          <div className="arrow-left">
            <span><FontAwesomeIcon icon={faChevronLeft} /></span>
          </div>
          <div className="index-box">
            <div className="index-tab">
              <ul>
                <li className="first">
                  HONG KONG-LISTED
                  <div className="arrow">
                  </div>
                </li>
                <li className="second">MAINLAND-LISTED</li>
                <li className="third">CROSS-MARKET</li>
              </ul>
            </div>
            <div className="index-cont m-0">
              <div className="index-col px-2 mt-3 mb-3 border-right d-flex">
                <div className="arrow">
                  <div className={hsIndexPositiveOrNegative === "+" ? "up" : "down"}></div>
                </div>
                <div className="box-number">
                  <div className="title">Hang Seng Index</div>
                  <div className="d-flex">
                    <div style={{ fontSize: 25, fontWeight: 700, flex: 1 }}>{utils.numberWithCommas(hsIndex.toString())}</div>
                    <div className={hsIndexPositiveOrNegative === "+" ? "change-u" : "change-d"}>
                      <p className="m-0 text-right index-change" style={{ lineHeight: 1.3 }}>{hsIndexPositiveOrNegative}{utils.numberWithCommas(Math.abs(hsIndexChange).toString())}</p>
                      <p className="m-0 text-right" style={{ lineHeight: 1.3 }}>{hsIndexPositiveOrNegative}{utils.numberWithCommas(Math.abs(hsIndexPercent).toString())}%</p>
                    </div>
                  </div>
                  <div className="text-right mt-2" style={{ fontSize: '90%' }}>
                    Last Update: {time}
                  </div>
                </div>
              </div>
              <div className="index-col px-2 mt-3 mb-3 border-right d-flex">
                <div className="arrow">
                  <div className={hscePositiveOrNegative === "+" ? "up" : "down"}>
                  </div>
                </div>
                <div className="box-number">
                  <div className="title">Hang Seng China Enterprises Index</div>
                  <div className="d-flex">
                    <div style={{ fontSize: 25, fontWeight: 700, flex: 1 }}>{utils.numberWithCommas(hsce.toString())}</div>
                    <div className={hscePositiveOrNegative === "+" ? "change-u" : "change-d"}>
                      <p className="m-0 text-right index-change" style={{ lineHeight: 1.3 }}>{hscePositiveOrNegative}{utils.numberWithCommas(Math.abs(hsceChange).toString())}</p>
                      <p className="m-0 text-right" style={{ lineHeight: 1.3 }}>{hscePositiveOrNegative}{utils.numberWithCommas(Math.abs(hscePercent).toString())}%</p>
                    </div>
                  </div>
                  <div className="text-right mt-2" style={{ fontSize: '90%' }}>
                    Last Update: {time}
                  </div>
                </div>
              </div>
              <div className="index-col px-2 mt-3 mb-3 border-right d-flex">
                <div className="arrow">
                  <div className={hsTechPositiveOrNegative === "+" ? "up" : "down"}></div>
                </div>
                <div className="box-number">
                  <div className="title">Hang Seng TECH Index</div>
                  <div className="d-flex">
                    <div style={{ fontSize: 25, fontWeight: 700, flex: 1 }}>{utils.numberWithCommas(hsTech.toString())}</div>
                    <div className={hsTechPositiveOrNegative === "+" ? "change-u" : "change-d"}>
                      <p className="m-0 text-right index-change" style={{ lineHeight: 1.3 }}>{hsTechPositiveOrNegative}{utils.numberWithCommas(Math.abs(hsTechChange).toString())}</p>
                      <p className="m-0 text-right" style={{ lineHeight: 1.3 }}>{hsTechPositiveOrNegative}{utils.numberWithCommas(Math.abs(hsTechPercent).toString())}%</p>
                    </div>
                  </div>
                  <div className="text-right mt-2" style={{ fontSize: '90%' }}>
                    Last Update: {time}
                  </div>
                </div>
              </div>
              <div className="index-col px-2 mt-3 mb-3 d-flex">
                <div className="arrow">
                  <div className={hsComIndexPositiveOrNegative === "+" ? "up" : "down"}></div>
                </div>
                <div className="box-number">
                  <div className="title">Hang Seng Composite Index</div>
                  <div className="d-flex">
                    <div style={{ fontSize: 25, fontWeight: 700, flex: 1 }}>{utils.numberWithCommas(hsComIndex.toString())}</div>
                    <div className={hsComIndexPositiveOrNegative === "+" ? "change-u" : "change-d"}>
                      <p className="m-0 text-right index-change" style={{ lineHeight: 1.3 }}>{hsComIndexPositiveOrNegative}{utils.numberWithCommas(Math.abs(hsComIndexChange).toString())}</p>
                      <p className="m-0 text-right" style={{ lineHeight: 1.3 }}>{hsComIndexPositiveOrNegative}{utils.numberWithCommas(Math.abs(hsComIndexPercent).toString())}%</p>
                    </div>
                  </div>
                  <div className="text-right mt-2" style={{ fontSize: '90%' }}>
                    Last Update: {time}
                  </div>
                </div>
              </div>
            </div>
            <div className="today-result">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    {morningClose()}
                  </tr>
                  <tr>
                    {eveningClose()}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="arrow-right">
            <span><FontAwesomeIcon icon={faChevronRight} /></span>
          </div>
        </div>
        {/* <div className="mob-index">
          <div className="second">
            <span>MAINLAND-LISTED</span> <i className="fas fa-chevron-down"></i>
          </div>
          <div className="third">
            <span>CROSS-MARKET</span> <i className="fas fa-chevron-down"></i>
          </div>
        </div> */}
      </div>
      <div className="history">
        <h4>Statistics</h4>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th rowSpan={2}>Date</th>
              <th colSpan={2}>Morning Closed</th>
              <th colSpan={2}>Evening Closed</th>
            </tr>
            <tr>
              <th>Up</th>
              <th>Down</th>
              <th>Up</th>
              <th>Down</th>
            </tr>
          </thead>
          <tbody>
            {renderStat()}
          </tbody>
        </table>
      </div>
      <div className="hk-final-footer"></div>
    </div>
  </div>
}

export default App;
